/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as wjGrid from '@grapecity/wijmo.grid';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { Button, generateFileName } from '@/components/Elements';
import { CustomGridColumn } from '@/components/Wijmo/FlexGrid';
import { ListContents, ListContentsHandler } from '@/components/Wijmo/GridContents';
import { DivisionGradeFieldsFragment, useFetchDivisionGradesQuery } from '@/generated/graphql';

export const FetchDivisionGradeLists = () => {
  const { data, isSuccess, isFetched } = useFetchDivisionGradesQuery({}, { suspense: true });

  const [listData, setListData] = useState<DivisionGradeFieldsFragment[]>([]);

  useEffect(() => {
    if (isSuccess && data) {
      const newList = (data.divisionGrades || []).filter(
        (grade): grade is DivisionGradeFieldsFragment => grade !== null && grade !== undefined
      );
      setListData(newList);
    }
  }, [data, isSuccess]);

  // initializedメソッドの定義
  const initialized = (s: wjGrid.FlexGrid) => {
    // create extra header row
    const extraRow = new wjGrid.Row();
    extraRow.allowMerging = true;

    // add extra header row to the grid
    const panel = s.columnHeaders;
    panel.rows.splice(0, 0, extraRow);

    // populate the extra header row
    for (let colIndex = 2; colIndex <= 5; colIndex++) {
      panel.setCellData(0, colIndex, '基本情報');
    }
    for (let colIndex = 6; colIndex <= 8; colIndex++) {
      panel.setCellData(0, colIndex, 'DIO分析');
    }
    for (let colIndex = 9; colIndex <= 22; colIndex++) {
      panel.setCellData(0, colIndex, 'その他過去実績');
    }

    const mergeColumn3 = ['divisionCode', 'divisionName'];

    mergeColumn3.forEach((binding) => {
      const col = s.getColumn(binding);
      if (col) {
        // colがnullでないことを確認
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
        panel.setCellData(1, col.index, col.header);
      } else {
        console.error(`Column with binding '${binding}' not found.`);
      }
    });

    // center-align merged header cells
    s.formatItem.addHandler((v, e) => {
      if (e.panel === v.columnHeaders && e.range.rowSpan >= 1) {
        const html = e.cell.innerHTML;
        e.cell.innerHTML = `<div class="v-center">${html}</div>`;
      }
    });

    s.autoGenerateColumns = false;
  };

  const listContentsRef = useRef<ListContentsHandler>(null);

  const handleExport = () => {
    if (listContentsRef.current) {
      listContentsRef.current.exportData(generateFileName('部門成績コレ'));
    }
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="col-12 mb-5">
          <h2 className="fk__heading__contents">部門成績一覧</h2>
          <div className="card shadow-sm">
            <div className="card-body">
              <Button variant="secondary" onClick={handleExport}>
                エクスポート
              </Button>
              <div className="row">
                <ListContents
                  itemsSource={listData}
                  totalCount={listData.length}
                  isFetched={isFetched}
                  frozenColumns={2}
                  allowMerging="AllHeaders"
                  initialized={initialized} // カスタム初期化メソッドを渡す
                  ref={listContentsRef}
                >
                  <CustomGridColumn
                    binding="divisionCode"
                    header="部門コード"
                    width={80}
                    isReadOnly
                    align="center"
                  />
                  <CustomGridColumn binding="divisionName" width={150} header="部門名" isReadOnly />
                  <CustomGridColumn binding="totalSku" header="総SKU数" format="n0" isReadOnly />
                  <CustomGridColumn
                    binding="totalSkuNew"
                    header="新商品SKU"
                    width={110}
                    format="n0"
                    isReadOnly
                  />
                  <CustomGridColumn
                    binding="totalSkuNewRate"
                    header="新商品構成比"
                    width={110}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="totalSkuNewSalesRate"
                    header="新商品売上構成比"
                    width={110}
                    isReadOnly
                    format="p2"
                  />
                  <CustomGridColumn binding="dio2year" header="前々期DIO" format="n2" isReadOnly />
                  <CustomGridColumn binding="dio1year" header="前期DIO" format="n2" isReadOnly />
                  <CustomGridColumn binding="dioCurrent" header="現在DIO" format="n2" isReadOnly />
                  <CustomGridColumn
                    binding="costRate2year"
                    header="前々期原価率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="costRate1year"
                    header="前期原価率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="costRateCurrent"
                    header="今期原価率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="grossProfitRate2year"
                    header="前々期粗利率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="grossProfitRate1year"
                    header="前期粗利率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="grossProfitRateCurrent"
                    header="今期粗利率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="targetGrossMarginRate"
                    header="目標粗利率(％)"
                    width={100}
                    isReadOnly
                    align="right"
                    format="p2"
                  />
                  <CustomGridColumn
                    binding="recordHighSales"
                    header="過去最高売上"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                  <CustomGridColumn
                    binding="totalSalesAmount2year"
                    header="前々期売上"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                  <CustomGridColumn
                    binding="totalSalesAmount1year"
                    header="前期売上"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                  <CustomGridColumn
                    binding="totalSalesAmountCurrent"
                    header="今期累計売上"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                  <CustomGridColumn
                    binding="discontinuedProductAmount2year"
                    header="前々期廃盤累計"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                  <CustomGridColumn
                    binding="discontinuedProductAmount1year"
                    header="前期廃盤累計"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                  <CustomGridColumn
                    binding="discontinuedProductAmountCurrent"
                    header="今期廃盤累計"
                    width={100}
                    isReadOnly
                    format="c0"
                  />
                </ListContents>
              </div>

              <div className="mt-4">
                <div className="mb-2 text-lg font-bold">算出期間</div>
                <div className="ml-4">
                  <div className="flex">
                    <div className="w-16 mb-2">現　在</div>
                    <div>
                      {data?.divisionTerms?.current?.termFrom.replaceAll('-', '/')} ～{' '}
                      {data?.divisionTerms?.current?.termTo.replaceAll('-', '/')}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-16 mb-2">今　期</div>
                    <div>
                      {data?.divisionTerms?.currentTerm?.termFrom.replaceAll('-', '/')} ～{' '}
                      {data?.divisionTerms?.currentTerm?.termTo.replaceAll('-', '/')}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-16 mb-2">前　期</div>
                    <div>
                      {data?.divisionTerms?.previousTerm?.termFrom.replaceAll('-', '/')} ～{' '}
                      {data?.divisionTerms?.previousTerm?.termTo.replaceAll('-', '/')}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-16">前々期</div>
                    <div>
                      {data?.divisionTerms?.twoTermsAgo?.termFrom.replaceAll('-', '/')} ～{' '}
                      {data?.divisionTerms?.twoTermsAgo?.termTo.replaceAll('-', '/')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
